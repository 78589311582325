.why-choose-five {
  padding: 120px 0 120px !important;
}

.feature-two__right {
  text-align: center;
}

.feature-two__right h4 {
  font-size: 25px;
  color: white !important;
}

.feature-two {
  padding: 50px;
}

.listflex {
  display: flex;
  justify-content: space-between;
}
