.main-slider-four__content h4 {
  color: white !important;
  margin-bottom: 30px !important;
}

.feature-two__right {
  text-align: center;
}

.feature-two__right h4 {
  font-size: 25px;
  color: white !important;
}
